export default class Miss {
  constructor(action, reference, user_name,start_day, end_day, type_reference, reason, approved, files) {
    this.action = action;
    this.reference = reference;
    this.user_name = user_name;
    this.start_day = start_day;
    this.end_day = end_day;
    this.type_reference = type_reference;
    this.reason = reason;
    this.approved = approved;
    this.files = files == undefined ? [] : files;
    this.viewFileButton = true;
    this.looderFiles = false;
  }
}
