<template>
  <div class="card p-shadow-6">
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <h1>Aprovar Faltas</h1>
    <Divider />
    <div class="p-field p-grid">
      <div class="p-col-12 p-md-3 p-mt-1">
        <label for="year-selector">Ano</label>
        <InputNumber
          id="year-selector"
          name="year-selector"
          :value="selectedYear"
          class="p-col-10"
          inputStyle="width: 75px"
          showButtons
          buttonLayout="horizontal"
          incrementButtonIcon="pi pi-plus"
          decrementButtonIcon="pi pi-minus"
          :step="1"
          :min="currentYear - 10"
          :max="currentYear"
          :useGrouping="false"
          @input="yearChanged"
        />
      </div>
    </div>
    <Divider />
    <DataTable
      :value="missList"
      :filters.sync="filters"
      filterDisplay="row"
      :loading="loading"
      :paginator="true"
      :rows="20"
      class="p-datatable-sm"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rowsPerPageOptions="[10, 25, 50]"
      currentPageReportTemplate="Mostrar {first} ao {last} de {totalRecords} faltas"
      responsiveLayout="scroll"
    >
      <template #empty> Não existem despesas. </template>
      <template #loading> A carregar. Por favor Aguarde... </template>
      <Column
        field="user_name"
        header="Funcionário"
        sortable
        filterField="user_name"
      >
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            class="p-column-filter p-col-12"
            placeholder="Pesquisar por nome"
            v-model="filterModel.value"
            @input="filterCallback()"
          />
        </template>
        <template #body="slotProps">
          <span class="p-column-title">Funcionário:</span>
          {{ slotProps.data.user_name }}
        </template>
      </Column>
      <Column
        field="date_start_day"
        header="Data Início"
        sortable
        filterField="date_start_day"
        dataType="date"
      >
        <template #body="slotProps">
          <span class="p-column-title">Início:</span>
          {{ slotProps.data.start_day }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <v-date-picker
            v-model="filterModel.value"
            @input="filterCallback()"
            :masks="{
              input: 'YYYY-MM-DD',
            }"
          >
            <template v-slot="{ inputValue, inputEvents }">
              <input
                class="p-column-filter p-inputtext p-component"
                :value="inputValue"
                v-on="inputEvents"
              />
            </template>
          </v-date-picker>
        </template>
      </Column>
      <Column
        field="date_end_day"
        header="Data Fim"
        sortable
        filterField="date_end_day"
        dataType="date"
      >
        <template #body="slotProps">
          <span class="p-column-title">Fim:</span>
          {{ slotProps.data.end_day }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <v-date-picker
            v-model="filterModel.value"
            @input="filterCallback()"
            :masks="{
              input: 'YYYY-MM-DD',
            }"
          >
            <template v-slot="{ inputValue, inputEvents }">
              <input
                class="p-column-filter p-inputtext p-component"
                :value="inputValue"
                v-on="inputEvents"
              />
            </template>
          </v-date-picker>
        </template>
      </Column>
      <Column field="type" header="Tipo" sortable filterField="type">
        <template #body="slotProps">
          <span class="p-column-title">Tipo:</span>
          {{ slotProps.data.type }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            class="p-column-filter"
            v-model="filterModel.value"
            @input="filterCallback()"
          />
        </template>
      </Column>
      <Column field="reason" header="Observações" filterField="reason">
        <template #body="slotProps">
          <span class="p-column-title">Observações:</span>
          {{ slotProps.data.reason }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            class="p-column-filter"
            v-model="filterModel.value"
            @input="filterCallback()"
          />
        </template>
      </Column>
      <Column
        field="approved"
        header="Aprovada"
        filterField="approved"
        dataType="boolean"
        sortable
      >
        <template #body="slotProps">
          <span class="p-column-title">Aprovada:</span>
          {{ slotProps.data.approved == true ? "Sim" : "Não" }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <Dropdown
            v-model="filterModel.value"
            @input="filterCallback()"
            :options="[
              { value: false, label: 'Não' },
              { value: true, label: 'Sim' },
            ]"
            optionValue="value"
            optionLabel="label"
            class="p-column-filter"
            :showClear="true"
          >
          </Dropdown>
        </template>
      </Column>

      <Column>
        <template #body="slotProps">
          <Button
            icon="pi pi-eye"
            class="p-button-rounded p-button-success p-button-outlined p-mr-2"
            @click="viewMiss(slotProps.data)"
          />
        </template>
      </Column>
    </DataTable>

    <Dialog
      :visible.sync="missForm"
      :position="$isMobile() ? '' : 'top'"
      :style="{ width: $isMobile() ? '100vw' : '50vw' }"
      :header="'Ver Falta'"
      :modal="true"
      :contentStyle="{ overflow: 'hide' }"
    >
      <form name="missForm" class="p-col-12" style="min-height: 35vh">
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-12 p-md-6 p-mt-2">
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon">
                <i class="pi pi-calendar"></i>
              </span>
              <span class="p-float-label">
                <Calendar
                  :disabled="true"
                  id="start_day"
                  v-model="missItem.start_day"
                  dateFormat="yy-mm-dd"
                  :manualInput="false"
                  v-bind:class="[
                    { 'p-invalid': errors.has('start_day') },
                    'form-control',
                  ]"
                  name="start_day"
                />
                <label for="inputtext">Início</label>
              </span>
            </div>
          </div>

          <div class="p-field p-col-12 p-md-6 p-mt-2">
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon">
                <i class="pi pi-calendar"></i>
              </span>
              <span class="p-float-label">
                <Calendar
                  :disabled="true"
                  id="end_day"
                  v-model="missItem.end_day"
                  dateFormat="yy-mm-dd"
                  :manualInput="false"
                  v-bind:class="[
                    { 'p-invalid': errors.has('end_day') },
                    'form-control',
                  ]"
                  name="end_day"
                />
                <label for="inputtext">Fim</label>
              </span>
            </div>
          </div>

          <div class="p-field p-col-12 p-md-6 p-mt-3">
            <span class="p-float-label">
              <Dropdown
                :disabled="true"
                id="business"
                v-model="missItem.type_reference"
                name="type_reference"
                v-bind:class="[
                  { 'p-invalid': errors.has('type_reference') },
                  'form-control',
                ]"
                :inputId="'type_reference'"
                :showClear="true"
                :options="missTypeList"
                :optionLabel="'name'"
                :optionValue="'reference'"
                :dataKey="'reference'"
              />
              <label for="business">Tipo</label>
            </span>
          </div>

          <div class="p-field p-col-12 p-md-6 p-mt-3">
            <span class="p-float-label">
              <InputText
                :disabled="true"
                type="text"
                v-model="missItem.reason"
                v-bind:class="[
                  { 'p-invalid': errors.has('reason') },
                  'form-control',
                ]"
                :id="'reason'"
                :name="'reason'"
              />
              <label for="pats">Motivo da Falta</label>
            </span>
          </div>
          <div class="p-field p-grid p-col-12">
            <div class="p-col-12 p-md-2" v-if="missItem.viewFileButton">
              <Button
                label="Ver Ficheiros"
                icon="pi pi-eye"
                class="p-button-success p-button-outlined p-mb-2"
                style="width: 100%"
                @click="getMissFiles"
              />
            </div>
          </div>
          <Loading
            :active.sync="missItem.looderFiles"
            :is-full-page="true"
          ></Loading>
          <div
            class="p-field p-col-12 p-md-4"
            v-for="item in missItem.files"
            :key="item.referece"
          >
            <div v-if="item.type == 'pdf'" class="pdf-viewer">
              <embed
                :src="item.src"
                type="application/pdf"
                width="100%"
                height="300px"
              />
            </div>
            <div v-else style="text-align: center">
              <ImagePreview :src="item.src" alt="Image" width="200" preview />
            </div>
            <div class="p-col-12 p-text-center p-mt-1">
              <Button
                v-if="item.type == 'pdf'"
                icon="pi pi-search"
                class="p-button-rounded p-button-outlined p-button-raised"
                aria:haspopup="true"
                aria-controls="overlay_panel"
                @click="item.fullView = true"
              />
              <Sidebar :visible.sync="item.fullView" position="full">
                <div v-if="item.type == 'pdf'" class="pdf-fullview">
                  <embed
                    :src="item.src"
                    type="application/pdf"
                    width="100%"
                    height="500px"
                  />
                </div>
              </Sidebar>
            </div>
          </div>
        </div>
      </form>
      <template #footer>
        <Button
          label="Aprovar"
          icon="pi pi-check"
          class="p-button-text p-button-success"
          @click="approveMiss"
        />
        <Button
          label="Fechar"
          icon="pi pi-times"
          class="p-button-text p-button-danger"
          @click="closeForm"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import missService from "../services/miss.service";
import MissModel from "../models/miss";
import loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import filesService from "../services/files.service";
import { FilterMatchMode } from "primevue/api/";

export default {
  name: "MissApprove",

  components: {
    Loading: loading,
  },
  data() {
    return {
      loading: true,
      missList: [],
      missTypeList: [],
      filters: {
        date_start_day: { value: null, matchMode: FilterMatchMode.DATE_IS },
        date_end_day: { value: null, matchMode: FilterMatchMode.DATE_IS },
        type: { value: null, matchMode: FilterMatchMode.CONTAINS },
        reason: { value: null, matchMode: FilterMatchMode.CONTAINS },
        approved: { value: null, matchMode: FilterMatchMode.EQUALS },
        user_name: { value: null, matchMode: FilterMatchMode.EQUALS },
      },
      selectedYear: new Date().getFullYear(),
      currentYear: new Date().getFullYear(),
      missItem: {},
      missForm: false,
    };
  },
  created() {
    this.loading = true;
    this.getMissType().then(() => this.getMissesNotApproved());
  },
  methods: {
    yearChanged(year) {
      if (this.selectedYear != year) {
        this.selectedYear = year;
        this.getMissesNotApproved();
      }
    },
    getMissType() {
      return missService.getMissTypes().then((response) => {
        return (this.missTypeList = response);
      });
    },
    getMissesNotApproved() {
      this.loading = true;
      return missService
        .getMissNotAproved(this.selectedYear)
        .then((response) => {
          response = response.map((v) => ({
            ...v,
            date_start_day: new Date(v.start_day),
            date_end_day: new Date(v.end_day),
          }));
          this.loading = false;
          return (this.missList = response);
        });
    },
    closeForm() {
      this.missForm = false;
      this.missItem = {};
    },
    viewMiss(info) {
      this.missItem = new MissModel("view");
      this.missItem.reference = info.reference;
      this.missItem.user_name = info.user_name;
      this.missItem.start_day = info.start_day;
      this.missItem.end_day = info.end_day;
      this.missItem.type_reference = info.type_reference;
      this.missItem.reason = info.reason;
      this.missItem.approved = info.approved;
      this.missItem.files = [];
      this.missForm = true;
    },
    approveMiss() {
      let missRef = this.missItem.reference;
      const areYou = (missAux) => {
        return missAux.reference == missRef;
      };
      let data = {
        reference: this.missItem.reference,
        approved_status: 1,
      };

      return missService.changeStatusMiss(data).then((response) => {
        if (!response) {
          return this.$toast.add({
            severity: "error",
            summary: "Erro",
            detail: "Ocorreu um erro ao aprovar a falta",
            life: 3000,
          });
        }
        if (response.approved == true) {
          let missIndex = this.missList.findIndex(areYou);
          if (missIndex >= 0) {
            this.missList.splice(missIndex, 1);
          }
        }
        this.closeForm();
        return this.$toast.add({
          severity: "success",
          summary: "Falta aprovada",
          detail: "A falta foi aprovada com sucesso",
          life: 3000,
        });
      });
    },
    getMissFiles() {
      this.missItem.files = [];
      this.missItem.looderFiles = true;
      let data = {
        origin_reference: this.missItem.reference,
      };
      filesService.getFileFromOriginReference(data).then((response) => {
        if (!response) {
          return this.$toast.add({
            severity: "error",
            summary: "Erro",
            detail: "Ocorreu um erro ao eliminar o ficheiro",
            life: 3000,
          });
        }
        response.forEach((file) => {
          file["fullView"] = false;
          if (file.type !== undefined && file.type == "pdf") {
            file.src = `data:application/${file.type};base64,${file.src}`;
          } else if (file.type !== undefined) {
            file.src = `data:image/${file.type};base64,${file.src}`;
          }

          this.missItem.files.push(file);
        });
        this.missItem.viewFileButton = false;
        this.missItem.looderFiles = false;
      });
    },
  },
};
</script>
<style scoped lang="scss">
#year-selector {
  width: 60px;
}

.image-preview {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 15vw;
  width: auto;
}

.image-view {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  max-width: 100% !important;
  max-height: 100% !important;
  height: 75vh !important;
  width: auto !important;
}

.pdf-viewer {
  text-align: center;
}
</style>
